// Função para remover caracteres não numéricos
function removeNonDigits(value) {
  return value.replace(/\D/g, '').slice(0, 11);
}

// Função para mascarar telefone
function maskPhone(value) {
  value = removeNonDigits(value);
  
  if (value.length > 10) {
    return value.replace(/^(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
  } else if (value.length > 6) {
    return value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
  } else if (value.length > 2) {
    return value.replace(/^(\d{2})(\d{0,5})/, '($1) $2');
  }
  return value;
}

// Handler para input de telefone
function handlePhoneInput(e) {
  let input = $(this);
  let cursorPos = input[0].selectionStart;
  let oldValue = input.val();
  let newValue = maskPhone(oldValue);
  
  // Calcula nova posição do cursor
  let oldLength = oldValue.length;
  let newLength = newValue.length;
  cursorPos = cursorPos + (newLength - oldLength);
  
  input.val(newValue);
  
  // Restaura posição do cursor
  input[0].setSelectionRange(cursorPos, cursorPos);

  // Habilita/desabilita botão submit
  const isValid = removeNonDigits(newValue).length >= 10;
  $('#btn-submit')
    .prop('disabled', !isValid)
    .toggleClass('disabled', !isValid);
}

$(document).ready(function() {
  $('.phone_input').on('input', handlePhoneInput);
});